<template>
  <validation-observer ref="observer">
    <div
      :class="{ 'is-active': displayJobSpecificationForm }"
      class="modal modal-z-index"
    >
      <div class="modal-background" @click="$emit('close')"></div>
      <div class="card modal-width modal-border modal-active">
        <header class="container modal-header-shadow card-header">
          <div
            class="column is-12 overflow-auto height-auto has-padding-left-40-desktop has-padding-right-40-desktop"
          >
            <div class="columns is-multiline is-mobile is-vcentered is-desktop">
              <div class="container column is-11">
                <div class="has-text-weight-bold is-size-6">
                  {{ $t('Job Specification') }}
                </div>
                <div class="is-size-7 has-text-grey">
                  {{ $t('Please fill your initial details to post a job') }}
                </div>
              </div>
              <div class="column is-1 modalbtn has-text-right">
                <span class="pointer" @click="$emit('close')">
                  <vue-icon-base
                    height="14"
                    width="14"
                    :path="$options.icons[$kc('uni-multiply')]"
                  >
                  </vue-icon-base>
                </span>
              </div>
            </div>
          </div>
        </header>
        <div
          id="topOfForm"
          class="card-content container modal-height overflow-auto"
        >
          <div class="columns has-padding-left-20-desktop is-multiline">
            <div v-if="nonFieldErrors.length" class="column is-12">
              <vue-form-errors
                :non-field-errors="nonFieldErrors"
              ></vue-form-errors>
            </div>
            <div class="column is-6-desktop is-12-mobile">
              <validation-provider
                v-slot="{ errors }"
                :name="$t('Minimum Educational Qualification')"
                rules="required"
              >
                <div class="field">
                  <label class="label is-size-6 has-text-grey">
                    <vue-icon-base
                      height="16"
                      width="16"
                      :path="$options.icons[$kc('uni-book-open')]"
                    >
                    </vue-icon-base>
                    {{ $t('Minimum Educational Qualification') }}
                    <span class="has-text-danger">*</span>
                  </label>
                  <div class="control">
                    <vue-auto-complete
                      v-model="formValues.education_level"
                      :error="!!(errors.length || fieldErrors.education_level)"
                      :initial-items="educationChoices"
                      :placeholder="$t('Education')"
                      item-text="name"
                      item-value="id"
                      @updateSelectedItems="
                        formValues.selectedEducationLevel =
                          $event && $event[0].name
                      "
                    ></vue-auto-complete>
                  </div>
                  <p class="field-error help is-danger">
                    {{ errors[0] || fieldErrors.education_level }}
                  </p>
                </div>
              </validation-provider>
              <div class="pt-0 pb-1 checkbox has-text-grey">
                <input
                  v-model="education_required"
                  :disabled="!(formValues.education_level !== 'not_required')"
                  type="checkbox"
                />
                <span class="ml-1">{{
                  $t('Must have this education qualification')
                }}</span>
              </div>
            </div>
            <div class="column is-6">
              <label class="label is-size-6 has-text-grey">
                <vue-icon-base
                  height="16"
                  width="16"
                  :path="$options.icons[$kc('uni-briefcase')]"
                ></vue-icon-base>
                {{ $t('Minimum Experience Required') }}
                <span class="has-text-danger">*</span>
              </label>
              <div class="columns is-multiline is-mobile mb-0">
                <div class="column is-8 pr-0 pb-0">
                  <validation-provider
                    v-slot="{ errors }"
                    :name="$t('Minimum Experience Required')"
                    rules="required|numeric|min_value:0"
                  >
                    <div class="field">
                      <div class="control">
                        <input
                          v-model="formValues.experience"
                          min="0"
                          :class="{
                            'is-danger':
                              errors.length || get(fieldErrors, 'experience')
                          }"
                          class="input"
                          type="number"
                          @keyup.enter="signup"
                        />
                      </div>
                      <p class="field-error help is-danger">
                        {{ errors[0] || fieldErrors.experience }}
                      </p>
                    </div>
                  </validation-provider>
                </div>
                <div class="column is-4 pl-0 pb-0">
                  <div class="select is-full-width">
                    <select v-model="formValues.unit" class="is-full-width">
                      <option
                        v-for="(durationItem, nindex) in durationChoices"
                        :key="nindex"
                        :value="durationItem.value"
                      >
                        {{ $t(durationItem.name) }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="pt-0 pb-1 checkbox has-text-grey">
                <input
                  v-model="experience_required"
                  :disabled="
                    !(formValues.experience && formValues.experience !== '0')
                  "
                  type="checkbox"
                />
                <span class="ml-1">{{
                  $t('Must have this years of experience')
                }}</span>
              </div>
            </div>
            <div class="column is-12 py-0"><hr class="my-1" /></div>
            <div class="column is-12 has-text-weight-bold">
              {{ $t('Specific Requirements') }}
            </div>
            <div class="column is-12">
              <div class="is-multiline is-mobile is-flex is-flex-wrap">
                <div v-if="!addWorkStandard" class="has-margin-right-10 mb-2">
                  <button
                    class="button button-position is-size-8 has-text-weight-bold has-text-employer is-light"
                    @click="addWorkStandard = true"
                  >
                    <span class="mr-1">{{ $t('Add Work Standard') }}</span>
                    <vue-icon-base
                      height="16"
                      width="16"
                      :path="$options.icons[$kc('uni-plus')]"
                    >
                    </vue-icon-base>
                  </button>
                </div>
                <div v-if="!addSkill" class="has-margin-right-10 mb-2">
                  <button
                    class="button button-position is-size-8 has-text-weight-bold has-text-employer is-light"
                    @click="addSkill = true"
                  >
                    <span class="mr-1">{{ $t('Add Skill') }}</span>
                    <vue-icon-base
                      height="16"
                      width="16"
                      :path="$options.icons[$kc('uni-plus')]"
                    >
                    </vue-icon-base>
                  </button>
                </div>
                <div
                  v-if="!addPersonalQuality"
                  class="has-margin-right-10 mb-2"
                >
                  <button
                    class="button button-position is-size-8 has-text-weight-bold has-text-employer is-light"
                    @click="addPersonalQuality = true"
                  >
                    <span class="mr-1">{{ $t('Add Personal Qualities') }}</span>
                    <vue-icon-base
                      height="16"
                      width="16"
                      :path="$options.icons[$kc('uni-plus')]"
                    >
                    </vue-icon-base>
                  </button>
                </div>
                <div v-if="!addRequiredKnowledge" class="is-fullwidth">
                  <button
                    class="button button-position is-size-8 has-text-weight-bold has-text-employer is-light"
                    @click="addRequiredKnowledge = true"
                  >
                    <span class="mr-1">{{ $t('Add Required Knowledge') }}</span>
                    <vue-icon-base
                      height="16"
                      width="16"
                      :path="$options.icons[$kc('uni-plus')]"
                    >
                    </vue-icon-base>
                  </button>
                </div>
              </div>
            </div>
            <div v-if="addWorkStandard" class="column is-12">
              <div class="columns is-multiline is-mobile">
                <div class="column is-12 py-0">
                  <label class="label is-size-6 has-text-grey">
                    <vue-icon-base
                      height="16"
                      width="16"
                      :path="$options.icons[$kc('uni-bag')]"
                    >
                    </vue-icon-base>
                    {{ $t('Work Standards') }}
                  </label>
                </div>
                <div class="column is-11 pt-0">
                  <validation-provider
                    v-slot="{ errors }"
                    :name="$t('Work Standards')"
                    rules="max_length_10"
                  >
                    <vue-auto-complete
                      v-model="formValues.work_standards"
                      add-item-title="Work Standard"
                      item-text="name"
                      add-item-button
                      item-value="id"
                      endpoint="/api/v1/common/work-standards/"
                      allow-multiple
                      allow-create
                      @updateSelectedItems="
                        formValues.selectedWorkStandards = $event
                      "
                    ></vue-auto-complete>
                    <p class="field-error help is-danger">
                      {{ errors[0] || fieldErrors.work_standards }}
                    </p>
                  </validation-provider>
                </div>
                <div class="column is-1">
                  <span
                    class="pointer"
                    @click="
                      addWorkStandard = false
                      ;(formValues.work_standards = []),
                        (formValues.selectedWorkStandards = [])
                    "
                    ><vue-icon-base
                      height="23"
                      class="has-text-danger"
                      width="23"
                      :path="$options.icons[$kc('uni-trash-alt')]"
                    >
                    </vue-icon-base
                  ></span>
                </div>
              </div>
            </div>
            <div v-if="addSkill" class="column is-12">
              <div class="columns is-multiline is-mobile">
                <div class="column is-12 py-0">
                  <label class="label is-size-6 has-text-grey">
                    <vue-icon-base
                      height="16"
                      width="16"
                      :path="$options.icons[$kc('uni-science')]"
                    >
                    </vue-icon-base>
                    {{ $t('skills') }}
                  </label>
                </div>
                <div class="column is-11 pt-0">
                  <validation-provider
                    v-slot="{ errors }"
                    :name="$t('skills')"
                    rules="max_length_10"
                  >
                    <vue-auto-complete
                      v-model="formValues.skills"
                      add-item-title="skills"
                      item-text="name"
                      add-item-button
                      item-value="id"
                      endpoint="/api/v1/common/skills/"
                      allow-multiple
                      allow-create
                      @updateSelectedItems="formValues.selectedSkills = $event"
                    ></vue-auto-complete>
                    <p class="field-error help is-danger">
                      {{ errors[0] || fieldErrors.skills }}
                    </p>
                  </validation-provider>
                </div>
                <div class="column is-1">
                  <span
                    class="pointer"
                    @click="
                      addSkill = false
                      formValues.skills = []
                      formValues.selectedSkills = []
                    "
                    ><vue-icon-base
                      height="23"
                      class="has-text-danger"
                      width="23"
                      :path="$options.icons[$kc('uni-trash-alt')]"
                    >
                    </vue-icon-base
                  ></span>
                </div>
              </div>
            </div>
            <div v-if="addPersonalQuality" class="column is-12">
              <div class="columns is-multiline is-mobile">
                <div class="column is-12 py-0">
                  <label class="label is-size-6 has-text-grey">
                    <vue-icon-base
                      height="16"
                      width="16"
                      :path="$options.icons[$kc('uni-user-circle')]"
                    >
                    </vue-icon-base>
                    {{ $t('Personal Qualities') }}
                  </label>
                </div>
                <div class="column is-11 pt-0">
                  <validation-provider
                    v-slot="{ errors }"
                    :name="$t('Personal Qualities')"
                    rules="max_length_10"
                  >
                    <vue-auto-complete
                      v-model="formValues.personal_qualities"
                      add-item-title="Personal Qualities"
                      item-text="name"
                      add-item-button
                      item-value="id"
                      endpoint="/api/v1/common/personal-qualities/"
                      allow-multiple
                      allow-create
                      @updateSelectedItems="
                        formValues.selectedPersonalQualities = $event
                      "
                    ></vue-auto-complete>
                    <p class="field-error help is-danger">
                      {{ errors[0] || fieldErrors.personal_qualities }}
                    </p>
                  </validation-provider>
                </div>
                <div class="column is-1">
                  <span
                    class="pointer"
                    @click="
                      addPersonalQuality = false
                      formValues.personal_qualities = []
                      formValues.selectedPersonalQualities = []
                    "
                    ><vue-icon-base
                      height="23"
                      class="has-text-danger"
                      width="23"
                      :path="$options.icons[$kc('uni-trash-alt')]"
                    >
                    </vue-icon-base
                  ></span>
                </div>
              </div>
            </div>
            <div v-if="addRequiredKnowledge" class="column is-12">
              <div class="columns is-multiline is-mobile">
                <div class="column is-12 py-0">
                  <label class="label is-size-6 has-text-grey">
                    <vue-icon-base
                      height="16"
                      width="16"
                      :path="$options.icons[$kc('uni-book-reader')]"
                    >
                    </vue-icon-base>
                    {{ $t('Required Knowledge') }}
                  </label>
                </div>
                <div class="column is-11 pt-0">
                  <validation-provider
                    v-slot="{ errors }"
                    :name="$t('Required Knowledge')"
                    rules="max_length_10"
                  >
                    <vue-auto-complete
                      v-model="formValues.required_knowledge"
                      add-item-title="Required Knowledge"
                      item-text="name"
                      add-item-button
                      item-value="id"
                      endpoint="/api/v1/common/required-knowledge/"
                      allow-multiple
                      allow-create
                      @updateSelectedItems="
                        formValues.selectedRequiredKnowledge = $event
                      "
                    ></vue-auto-complete>
                    <p class="field-error help is-danger">
                      {{ errors[0] || fieldErrors.required_knowledge }}
                    </p>
                  </validation-provider>
                </div>
                <div class="column is-1">
                  <span
                    class="pointer"
                    @click="
                      addRequiredKnowledge = false
                      formValues.required_knowledge = []
                      formValues.selectedRequiredKnowledge = []
                    "
                    ><vue-icon-base
                      height="23"
                      class="has-text-danger"
                      width="23"
                      :path="$options.icons[$kc('uni-trash-alt')]"
                    >
                    </vue-icon-base
                  ></span>
                </div>
              </div>
            </div>
            <div class="column is-12 py-0"><hr class="my-1" /></div>
          </div>
        </div>
        <div class="container has-padding-20 is-top-paddingless-desktop">
          <div class="columns is-multiline is-mobile">
            <div
              class="column has-text-right is-12-desktop is-12-mobile is-top-paddingless-desktop"
            >
              <button
                class="button previous-button-width is-outlined is-employer has-text-weight-medium is-small"
                @click="gotoPreviousStep()"
                v-text="$t('Previous Step')"
              ></button>
              <button
                class="button button-width is-jobseeker has-text-white has-text-weight-medium is-small"
                :disabled="disabled"
                @click="updateJobSpecificationModal()"
                v-text="$t('Next Step')"
              ></button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </validation-observer>
</template>
<script>
import FormMixin from '@/mixins/veeValidate'
import VueAutoComplete from '@/components/Commons/VueAutoComplete'
import VueIconBase from '@/components/Commons/VueIconBase'
import {
  uniMultiply,
  uniBookOpen,
  uniBriefcase,
  uniScience,
  uniPlus,
  uniTrashAlt,
  uniEye,
  uniUserCircle,
  uniBookReader,
  uniBag
} from '@/helpers/icons'
import { extend } from 'vee-validate'
import { mapGetters, mapMutations } from 'vuex'
export default {
  icons: {
    uniMultiply,
    uniBookOpen,
    uniBriefcase,
    uniScience,
    uniPlus,
    uniTrashAlt,
    uniEye,
    uniUserCircle,
    uniBookReader,
    uniBag
  },
  components: { VueAutoComplete, VueIconBase },
  mixins: [FormMixin],
  props: {
    templateDetails: {
      type: Object,
      default() {
        return {}
      }
    },
    jobDetails: {
      type: Object,
      default() {
        return {}
      }
    },
    displayJobSpecificationForm: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      disabled: false,
      addSkill: false,
      addPersonalQuality: false,
      addWorkStandard: false,
      addRequiredKnowledge: false,
      education_required: false,
      experience_required: false,
      educationQualifications: [],
      durationChoices: [
        {
          name: 'Year',
          value: 'Year'
        }
      ],
      educationChoices: [],
      slug: '',
      formValues: {
        education_level: 'abc',
        selectedSkills: [],
        selectedPersonalQualities: [],
        selectedRequiredKnowledge: [],
        selectedWorkStandards: [],
        selectedEducationLevel: '',
        unit: 'Year',
        education_preference: 'not_required',
        experience_preference: 'not_required',
        experience: 1,
        skills: [],
        personal_qualities: [],
        work_standards: [],
        required_knowledge: []
      }
    }
  },
  computed: {
    ...mapGetters({
      getCurrentJobSlug: 'job/getCurrentJobSlug',
      getCurrentJobDetail: 'job/getCurrentJobDetail'
    }),
    educationLevel() {
      if (this.formValues.education_level === 'not_required') {
        return null
      } else {
        return this.formValues.education_level
      }
    },
    minExperience() {
      if (this.formValues.experience_preference === 'not_required') {
        return null
      } else {
        return this.formValues.experience * 12
      }
    }
  },
  watch: {
    templateDetails: {
      handler(val) {
        if (Object.keys(val).length) {
          this.populateTemplateDetails()
        }
      },
      deep: true,
      immediate: true
    },
    jobDetails: {
      handler(val) {
        if (Object.keys(val).length) {
          this.populateFormValues(val)
        }
      },
      deep: true,
      immediate: true
    },
    'formValues.education_level': {
      handler(val) {
        if (val && val === 'not_required') {
          this.formValues.education_preference = 'not_required'
          this.education_required = false
        }
      }
    },
    'formValues.experience': {
      handler(val) {
        if (val && val === '0') {
          this.experience_required = false
        }
      }
    },
    education_required: {
      handler(val) {
        if (val) {
          this.formValues.education_preference = 'required'
        } else if (!val && this.formValues.education_level) {
          this.formValues.education_preference = 'preferred'
        } else if (!val && !this.formValues.education_level) {
          this.formValues.education_preference = 'not_required'
        }
      }
    },
    experience_required: {
      handler(val) {
        if (val) {
          this.formValues.experience_preference = 'required'
        } else if (!val && this.formValues.experience) {
          this.formValues.experience_preference = 'preferred'
        } else if (!val && !this.formValues.experience) {
          this.formValues.experience_preference = 'not_required'
        }
      }
    }
  },
  created() {
    this.addMaxLengthvalidator()
    this.getEducationLevel()
    this.fetchData()
  },
  methods: {
    ...mapMutations({
      setCurrentJobDetail: 'job/setCurrentJobDetail'
    }),
    fetchData() {
      this.slug = document.URL.split('=')[1].split('&')[0]
      this.$axios.$get(`/api/v1/job/${this.slug}/`).then(response => {
        this.populateFormValues(response)
      })
    },
    getEducationLevel() {
      const endpoint = `/api/v1/common/education-degrees/`
      this.$axios.$get(endpoint).then(response => {
        this.educationQualifications = response.results
        this.educationChoices = [
          { id: 'not_required', name: 'Not Required' },
          ...response.results
        ]
        this.formValues.education_level = {
          id: 'not_required',
          name: 'Not Required'
        }
        this.formValues.education_qualifications = this.educationQualifications[0].id
      })
    },
    populateTemplateDetails() {
      this.formValues.skills = this.templateDetails.skills
        ? this.templateDetails.skills
        : []
    },
    populateFormValues(response) {
      this.prepareEducationData(response)
      this.prepareExperienceData(response)
      if (response.skills.length) {
        this.addSkill = true
        this.formValues.skills = response.skills
      }
      if (response.personal_qualities && response.personal_qualities.length) {
        this.addPersonalQuality = true
        this.formValues.personal_qualities = response.personal_qualities
      }
      if (response.required_knowledge && response.required_knowledge.length) {
        this.addRequiredKnowledge = true
        this.formValues.required_knowledge = response.required_knowledge
      }
      if (response.work_standards && response.work_standards.length) {
        this.addWorkStandard = true
        this.formValues.work_standards = response.work_standards
      }
    },
    async updateJobSpecificationModal() {
      if (await this.validateAllFields()) {
        this.disabled = true
        const endpoint = `/api/v1/job/${this.slug}/job-specification-new/`
        this.$axios
          .$put(endpoint, this.constructFormValues())
          .then(response => {
            this.notifyUser(this.$t('Successfully updated Job Specification'))
            this.$emit('closeSpecForm')
            history.pushState(
              {},
              '',
              this.localePath({
                name: 'job-post',
                query: {
                  slug: this.slug,
                  step: '3'
                }
              })
            )
            this.$emit('open')
          })
          .catch(error => {
            this.getError(error)
            if (this.nonFieldErrors.length) {
              document
                .getElementById('topOfForm')
                .scrollIntoView({ block: 'start', behavior: 'smooth' })
            }
          })
      }
    },
    gotoPreviousStep() {
      this.$emit('basicInfo', {
        formValues: this.formValues
      })
      this.$emit('closeSpecForm')
      history.pushState(
        {},
        '',
        this.localePath({
          name: 'job-post',
          query: {
            slug: this.slug,
            step: '1'
          }
        })
      )
      this.$emit('openbasicform')
    },
    constructFormValues() {
      return {
        is_education_specific: !!(
          this.formValues.education_preference === 'required'
        ),
        education_level: this.educationLevel,
        is_experience_required: !!(
          this.formValues.experience_preference === 'required'
        ),
        min_experience_months: this.minExperience,
        experience_operator:
          this.formValues.experience_preference === 'not_required'
            ? null
            : '==',
        max_experience_months: null,
        is_skill_specific: !!this.formValues.skills.length,
        personal_qualities: this.formValues.personal_qualities,
        skills: this.formValues.skills,
        work_standards: this.formValues.work_standards,
        required_knowledge: this.formValues.required_knowledge
      }
    },
    addMaxLengthvalidator() {
      extend('max_length_10', {
        validate: value => {
          let validation = true
          validation = validation && value.length < 11
          return validation
        },
        message: `{_field_} ${this.$t('field must be less than or equal 10')}`
      })
    },
    prepareEducationData(response) {
      this.formValues.education_level = response.education_level
        ? response.education_level.id
        : 'not_required'
      if (response.is_education_specific) {
        this.formValues.education_preference = 'required'
        this.education_required = true
      } else if (!response.is_education_specific && response.education_level) {
        this.formValues.education_preference = 'preferred'
        this.education_required = false
      } else {
        this.formValues.education_preference = 'not_required'
        this.education_required = false
      }
    },
    prepareExperienceData(response) {
      this.formValues.experience = response.setting.min_experience_months / 12
      if (response.setting.is_experience_required) {
        this.formValues.experience_preference = 'required'
        this.experience_required = true
      } else if (
        !response.is_experience_required &&
        response.setting.min_experience_months
      ) {
        this.formValues.experience_preference = 'preferred'
        this.experience_required = false
      } else {
        this.formValues.experience_preference = 'not_required'
        this.experience_required = false
      }
    }
  }
}
</script>
<style scoped>
@media only screen and (max-width: 768px) {
  .modal-width {
    max-height: 90vh;
    bottom: 0;
    position: absolute;
  }
  .modal-height {
    height: 25rem;
    overflow: auto;
  }
  .previous-button-width {
    width: 28%;
  }
  .button-width {
    width: 70%;
  }
}
@media only screen and (min-width: 769px) {
  .modal-width {
    position: absolute;
    height: auto;
  }
  .modal-height {
    height: 21rem;
  }
}

.is-size-8 {
  font-size: 0.85rem;
}
.button-position {
  height: 60px;
  padding-left: 18px;
  padding-right: 18px;
}
</style>
